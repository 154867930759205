import '../styles/index.css';
import '../styles/micromodal.css';
import './animations';
import html from "../index.html";
import about from "../about.html";
import '../assets/images/dpc.jpg';
import '../assets/images/provider.jpg';
import '../assets/images/payors.jpg';
import '../assets/images/web-analytics.png';
import '../assets/images/hies.jpg';
import 'flowbite';
// Default theme
import '@splidejs/splide/css';
import emailjs from 'emailjs-com';

emailjs.init("gyWOo7evuZv5URIhO");

import Splide from '@splidejs/splide';
if (document.querySelector('.splide')) {
    const splide = new Splide('.splide', {
        type: 'loop',
        autoplay: true,
        interval: 5000,
        pauseOnHover: true,
        arrows: false,
        pagination: false
    }).mount();
}


// document.querySelector('.prev').addEventListener('click', () => {
//     splide.go('-${i}');
// })
// document.querySelector('.next').addEventListener('click', () => {
//     splide.go('+${i}');
// })


import MicroModal from 'micromodal';
MicroModal.init();

function sendEmail(e) {
    e.preventDefault();

    var templateParams = {
        from_name: document.getElementById('floating_name').value,
        from_email: document.getElementById('floating_email').value,
        message: document.getElementById('floating_message').value
    };

    emailjs.send('service_t73d2qd', 'template_8mm6gvk', templateParams)
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
}

document.getElementById('contactForm').addEventListener('submit', sendEmail);
