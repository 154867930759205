import anime from 'animejs/lib/anime.es.js';

anime({
    targets: '.home-title',
    translateY: [50, 0],
    duration: 700,
    opacity: [0, 1],
    easing: 'easeInOutQuad',
})
anime({
    targets: '.home-img-bg',
    duration: 700,
    opacity: [0, 1],
    easing: 'easeInOutQuad',
})
anime({
    targets: '.home-img',
    duration: 700,
    translateX: [50, 0],
    easing: 'easeInOutQuad',
})